// // @import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@font-face {
    font-family: 'Montserrat', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap" rel="stylesheet"');
    font-family: 'Dosis', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;500;600;700&display=swap');
    font-family: 'Poppins', sans-serif;
    src: url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap')
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure, 
// footer, header, hgroup, menu, nav, section {
//     display: block;
// }
body {
    line-height: 1;
}

.alert {
    background-color: #F44336;
    color: #FFF;
    text-align: center;
    padding: 10px;
    font-size: 18px;
}

a{
    text-decoration: none !important;
}

$color1: hsl(195, 22%, 96%);
$color2: #0B89B7;
$color2light: #0e99cb;
$small:576px;
$medium: 1024px;
$large: 1400px;

.notify-alert {
    position: absolute !important;
    width: fit-content;
    height: fit-content;
    z-index: 10;
    right: 0;
    top: 50px;
}

// /*********************************
// HEADER
// *********************************/
// header {
//     // background-color: #fff;
//     background: #0B89B7;
//     height: 80px;
//     width: 100%;
//     // position: fixed;
//     z-index: 10;
// }

// #accueil {
//     background: #0B89B7;
//     display:flex;
//     flex-direction: column;
//     height:80px;
//     @media (max-width:$medium) {
//         flex-direction: row;
//         background: none;
//     }
// }

// nav {
//     width: 100%;
//     margin-top: 25px;
//     display: flex;
//     flex-direction: column;
//     gap: 1.5rem;
//     z-index: 10;

//     .menu-principal {
//         display: flex;
//         justify-content: space-evenly;
//         @media (max-width:$medium) {
//             display: none;
//         }
//         > li {
//             height: 4rem;
//             @media (max-width:$medium) {
//                 height: 3rem;
//                 position:relative;
//             }
//         }
//     }
//     .sous-menu-dropdown li{
//         height: 1rem;
//     }

//     .menu-hasflyout {
//         position:relative;
//     }

//     a {
//         color: #FFF;
//         font-family: Montserrat;
//         font-size: 1.2rem;
//         font-style: normal;
//         font-weight: 700;
//         line-height: normal;
//         &:hover{
//         color: #C6F081;
//         }
//     }

//     .menu-principal>.menu-hasdropdown:hover > ul {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
//         position: absolute;
//         background-color: $color2;
//         padding: 1rem 2rem;
//         margin-top: 1.5rem;
//         @media (max-width:$medium) {
//             margin-top: 0.5rem;
//             position:absolute;
//             left: 15rem;
//             top: -1.5rem;
//             width:max-content;
//         }
//     }
//     // li {
//     //     height:1rem;
//     // }
//     .menu-hasdropdown:hover > .sous-menu-dropdown {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
       
//         background-color: $color2;
//         padding: 1rem 2rem;
//         margin-top: 1.75rem;
//         position:absolute;
//         top: -2rem;
//         left:20rem;
//         width:max-content;
//     }
//     .sous-menu-dropdown:hover > .sous-menu-dropdown {
//         display: flex;
//         flex-direction: column;
//         gap: 1rem;
       
//         background-color: $color2;
//         padding: 1rem 2rem;
//         // margin-top: 1.75rem;
//         // position:absolute;
//         // top: -1rem;
//         // left:13rem;
//     }
    
// }
header {
    position:relative;
}
#accueil{
    // position:relative;
    width:100%;
    min-height:80px;
    background: $color2;
    // padding: 0 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
nav ul{
    position: relative;
    display:flex;
    justify-content: space-between;
    z-index: 1;
}
nav ul li{
    position: relative;
    list-style: none;
    z-index: 1;
}
nav ul li a{
    color:#F7F7F7;
    padding:20px 25px;
    text-decoration: none;
    display:flex;
    justify-content: space-between;

    font-family: Montserrat;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

nav ul li a:hover{
    // background: #096e93;
    color: #C6F081;
}

nav ul li ul {
    position: absolute;
    left: 0;
    width: 350px;
    background: $color2;
    display: none;
}

nav ul li:hover > ul {
    display: block;
}

nav ul li ul li {
    position: relative;
    width: 100%;
    // border: 1px solid rgba(0,0,0,0.2) ;
}

nav ul li ul li ul {
    top:0;
    left: 350px;
}


// .menu-hasflyout a:hover + ul {
//     color:red;
// }

// .menu-dropdown, .sous-menu-dropdown {
//     display:none;
// }
@media (max-width: $medium) {
    #accueil {
        background: transparent;
    }
    .menu-principal {
        display:none;
    }
    
    header nav {
        position:absolute;
        width:50%;
        top:80px;
        left: 0;
        background: $color2;
    }

    // header nav ul li {
    //     width:100%
    // }

    header nav ul li ul {
        position: relative;
        width: 100%;
        left: 0;
    }
    header nav ul li ul {
        top:0;
        left: 0;
    }
    header nav ul li:hover ul {
        background: $color2light;
        width:100%;
        // li::before {
        //     content:"-";
        //     color:#F7F7F7;
        // }
    }
    header nav ul li ul li ul {
        top:0;
        left: 0;
        padding-left: 1rem;
        // background: transparent;
    }
}

.burger-button {
    position: fixed;
    color: $color2;
    top: 20px;
    left: 1rem;
    display: none;
    opacity:.5;
    z-index: 15;
    @media (max-width:$medium) {
        display: block;
    }
    &:hover{
        opacity:1;
    }
}

.is-opened {
    display:flex !important;
    flex-direction: column;
    background-color: $color2;
    width:fit-content;
    padding: 2rem 1rem 1rem 1rem;
}



.urgence {
    position:absolute;
    top: 10rem;
    width: 96%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top:1rem;
    @media (max-width:$medium) {
        width: 100%;
        // margin-top: 0rem;
        // height:50px;
    // z-index: 15
    }
}

.urgence ul{
    text-decoration: none;
    border-radius: 6px;
    background: #F44336;
    // width:max-content;
    color: #FFF;
    // color: black;
    font-family: Montserrat;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 1rem 2rem;
    cursor:pointer;
    &::before{
        display: block;
        content:"";
        background: url(img/ant-design_phone-filled.png) no-repeat;
        border: 1px solid #FFF;
        border-radius: 6px;
        display:block;
        float:left;
        line-height:0;
        width:1em;
        height:1em;
        margin-right:0.5em;
        background-size:100%;
        padding:.2em;
    }
    &:hover li{
        display:block;
    }
    li{
        margin-top:1em;
        display:none;
    }
}

// /* large styles */
// @media screen and (min-width: 1025px) {
// .menu {
//     // max-width: 1500px;
//     margin: 0 auto;
// }
// .marge-menu2 {
//     margin-left: 3rem;
// }
    
// .menu-principal{
//     height: 80px !important;
//     align-items: center !important;
    
// }
// .menu ul {
//     display: flex;
//     width: 100%;
//     height: auto;
//     // padding: 12px 36px;
//     justify-content: center;
//     align-items: center;
//     gap: 0.5rem;
//     list-style: none;
//     padding-left: .5rem;
//     padding-right: 0.5rem;
// }

// .menu,
// .menu a {
//     text-decoration: none;
//     color: #FFF;
//     font-family: Montserrat;
//     font-size: 1.2rem;
//     font-style: normal;
//     font-weight: 700;
//     line-height: normal;
//     :hover{
//         color: #C6F081;
//     }
// }

// .menu a {
//     display: flex;
//     align-items: center;
//     gap: 0.5rem;
// }

// .menu-dropdown,
// .menu input[type="checkbox"] {
//     display: none;
//     width: 100%; 
// }

// .menu label:hover {
//     cursor: pointer;
// }

// .menu-hasdropdown>ul {
//     background: #0B89B7;
// }

// .menu-dropdown a {
//     padding: 5px 10px;
//     // border-bottom: 1px solid #E6E6E6;
//     font-family: Montserrat;
//     font-size: 1rem;
// }

//     .menu>label,
//     .menu input[type="checkbox"] {
//         display: none;
//     }

//     .menu>ul>li {
//         display: inline-block;
//         >a {
//             padding: 1rem 0.5rem;
//         }
//     }

//     .menu-hasdropdown {
//         position: relative;
//     }
    
//     .menu-hasdropdown>ul {
//         background: #0B89B7;
//         display: none;
//         position: absolute;
//         min-width: max-content;
//         box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
//         padding: 12px 16px;
//         z-index: 1;

//     }
    
//     .menu-hasdropdown:hover>ul {
//         display: block;
//         animation: grow 0.5s ease-in-out;
//     }

//     .menu-hasflyout {
//         position: relative;
//     }

//     .menu-hasflyout>ul {
//         position: absolute;

//         left: 100%;
//         top: -12px;
//     }

//     .menu-hasflyout .menu-downicon {
//         display: none;
//     }
// }

// // /* narrow */
// @media screen and (max-width: 1024px) {

//     .menu>label {
//         background: var(--bleu, #0B89B7);
//         display: block;
//         color: #FFF;
//         font-family: Montserrat;
//         font-size: 18px;
//         font-style: normal;
//         font-weight: 700;
//         line-height: normal;
//         padding: 1em 2em;
//         text-align: left;
//     }
//     .menu input{
//         display: none;
//     }
//     // .menu a {
//     //     padding: 15px 20px;
//     //     background: var(--bleu, #0B89B7);
//     // }

//     .menu-principal {
//         display: none;
//     }
//     .is-opened {
//         display: flex !important;
//         position: absolute;
//         top:80px;
//         flex-direction: column;
//         background: var(--bleu, #0B89B7);
//         list-style: none;
//         gap: 1rem;
//         padding: 1rem 2rem;
//     }
    
//     .is-opened .menu-hasdropdown .menu-dropdown{
//         display:none;
//     }

//     .menu a {
//         display: flex;
//         align-items: center;
//         color: #fff;
//         font-family: Montserrat;
//         font-size: 18px;
//         font-style: normal;
//         font-weight: 700;
//         line-height: normal;
//         text-decoration: none;
//         gap: 0.5rem;
//     }
    
//     // .menu>ul,
//     // .menu-righticon {
//     //     display: none;
//     // }

//     // .menu>ul i {
//     //     float: right;
//     //     padding: 5px 10px;
//     //     background: #012c42;
//     // }

//     // .menu-dropdown a {
//     //     background: #024d74;
//     // }

//     // .menu-hasflyout>ul a {
//     //     background: #023d5b;
//     // }

//     .urgence a{
//         text-decoration: none;
//         border-radius: 6px;
//         background: #F44336;
//         // width:max-content;
//         color: #FFF;
//         // color: black;
//         font-family: Montserrat;
//         font-size: 18px;
//         font-style: normal;
//         font-weight: 700;
//         line-height: normal;
//         padding: 1em 2em;
//         z-index: -1;
//     }

//     input[type="checkbox"]:checked+ul {
//         display: block;
//         animation: grow 0.5s ease-in-out;
//     }
// }

/*********************************
anim header : animation quand je descend au scroll
*********************************/
.header-anim {
    position:fixed !important;
    // top: 0;
    // width: 100%;
    // background: #0B89B7;
    // transition: 0.2s;
    // margin: 0px !important;
    z-index: 20;
    // height: 80px !important;
    // + .urgence {

    // }

    @media (max-width:576px) {
        height: 80px !important;
    }
    &::before {
        content:"";
        width:50px;
    }
    .navigation {
        margin-top: 30px;
    }
    ul{
    //     max-width: 1350px;
    //     width: 100%;
        @media (max-width:$medium) {
            max-width: auto;
        }
        >li>a {
            font-size:1.2rem;
        }
    }

     li a{
        font-size: 1rem;
    }
}


// /*********************************
// ACCUEIL
// *********************************/

//ABOUT US

.about-us{
    display: flex;
    padding: 51px 90px;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    align-self: stretch;
    @media (max-width:976px) {
        flex-direction: column;
        padding: 31px 50px;
        
    }
    >svg{
        width: 400px;
        height: 401.144px;
        @media (max-width:976px) {
            display:none;
            // width: 50%;
            // height: 50%
            
        }
        @media (max-width:576px) {
            display: none;
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        flex: 1 0 0;
        .logo-sicteub{
            width: 373px;
            height: 147.631px;
            background: url(img/logo-sicteub-2.png);
            background-size: contain;
            background-repeat: no-repeat;
            // background: url(img/sicteub2.svg), #FFF -27px -126.184px / 107.239% 270.945% no-repeat;
            z-index: -1;
        }
        h1{
            align-self: stretch;
            color: var(--bleu, #0B89B7);
            // text-align: justify;
            
            /* titre de section */
            font-family: Dosis;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase; 
        }
        p{
            align-self: stretch;
            color: #000912;
            text-align: justify;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        a {
            text-decoration: none;
            color: var(--bleu, #0B89B7);
            background-color: #FFF;
            display: flex;
            padding: 12px 15px;
            align-items: flex-start;
            gap: 10px; 
            border-radius: 6px;
            border: 3px solid var(--bleu, #0B89B7);
        }
    }

}


//BLOC
.bloc{
    display: flex;
    // width: 1439px;
    padding: 51px 90px;
    align-items: center;
    gap: 5rem;
    background-color: #FFF;
    margin-bottom: 5rem;
    @media (max-width:976px) {
        flex-direction: column;
        gap: 3rem;
        margin-bottom: 3rem;
        padding: 31px 50px;
    }
    .bloc-content{
        display: flex;
        flex-direction: column;
        padding: 10px;
    }
}
.bloc-red-title{
    align-self: stretch;
    color: #F44336;
    text-align: justify;
    margin-bottom: 10px;
    /* titre de section */
    font-family: Dosis;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}
.bloc-paragraphe {
    align-self: stretch;
    color: #000;
    text-align: justify;
    margin-top: 10px;
    margin-bottom: 10px;
    /* text */
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.bloc-signature {
    align-self: stretch;
    color: #F44336;
    text-align: right;
    margin-bottom: 10px;
    /* text */
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bloc-image{
    width: 357.679px;
    height: 450px;
    flex-shrink: 0;
    // background: url(img/cadre-president.svg), transparent -14.128px -44.12px / 106.489% 119.926% no-repeat;;
    // fill: url(img/cadre-president.svg), lightgray -14.128px -44.12px / 106.489% 119.926% no-repeat;
    @media (max-width:976px) {
       width: 50%;
       height: 50%;
    }
}

//A LA UNE

.actu{
    display: flex;
    // width: 1440px;
    padding: 51px 90px;
    flex-direction: column;
    // align-items: center;
    gap: 30px;
    background: rgba(11, 137, 183, 0.10);
    @media (max-width:576px) {
        padding: 31px 50px;
        
    }
    h2{
        // width: 1260px;
        color: var(--noir, #000912);

        /* titre de section */
        font-family: Dosis;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        &::after {
            content:"";
            display: block;
            text-align: center;
            border-bottom: 2px solid  var(--bleu, #0B89B7);
            width:100px;
            // margin:0 auto;
            padding-top: 10px;
        }

    }
    .news-cards{
        display: flex;
        align-items: flex-start;
        
        gap: 16px;  
        @media (max-width:1024px) {
            flex-direction: column;
        }
    }
    .card {
        // display: grid;
        // width: 409.333px;
        flex-direction: column;
        flex:1;
        align-items: flex-start;
        border-radius: 16px;
        width: 30%;
        background: var(--background-background-primary, #FFF);

        /* drop shadow */
        box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
        @media (max-width:1024px) {
            width: 100%;
        }
        >div{
            justify-self: stretch;
            /* drop shadow */
            box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
            >img{
                width:100%;
                height: 250px;
                
                border-radius: 16px 16px 0px 0px;
            }
        }
    }
    .card-content{
        display: flex;
        padding: 24px;
        flex-direction: column;
        // align-items: flex-end;
        gap: 30px;
        // align-self: stretch;
        border-radius: 16px;
    }
    .card-title a{
        text-decoration: none;
        color: #F44336;
        // align-self: stretch;
        /* titre de section */
        font-family: Dosis;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;

    }
    .card-info {
        display: flex;
        padding: 10px 0px;
        // align-items: center;
        gap: 6px;
        // align-self: stretch;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        @media (max-width:576px) {
           flex-direction: column;
           padding: 0;
        }
        .news-date {
            font-size: 14px;
            // display: flex;
            // align-items: stretch;
            color: #000;
        }
        .point{
            // display: flex;
            // align-self: center;
            margin-bottom:5px;
        }
        .news-categorie{
            // display: flex;
            // align-items: stretch;
            color: #000;
            text-transform: uppercase;
        }
    }
    .card-description{
        align-self: stretch;
        color: var(--noir, #000912);
        /* text */
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

    }
    .card-button{
        display: flex;
        padding: 12px 15px;
        align-self: flex-end;
        // align-items: center;
        border-radius: 6px;
        background: var(--orange, #F44336);
        
        a{
            text-decoration: none;
            color: #FFF;
            /* text */
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    >a {
        padding: 12px 15px;
        margin: 0 auto;
        border-radius: 6px;
        border: 3px solid #0B89B7;
        /* text */
        color: var(--bleu, #0B89B7);
        text-align: justify;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: none;
    }
    +img{
        width:100%;
    }
}

// CARTE

#vague-noire {
    width: 100%;
}
#carte::before{
    content:"";
    background: url(img/vague-noire.png)  no-repeat center center/cover;
    position:absolute;
    top:-10vh;
    left:0px;
    width: 100%;
    height: 10vh;
    // z-index: -1;
    
}
#carte{
    position:relative;
    // z-index: -1;
    display: flex;
    flex-direction: column;
    gap: 3em;
    margin-bottom: 4em;
    // background: var(--noir, #000912);
    #sig{
        display:flex;
        flex-direction: column;
        background: var(--noir, #000912);
        padding: 51px 90px 200px;
        gap: 3em;
        @media (max-width:576px) {
            padding: 31px 50px 200px;
            
        }
        iframe{
            border-radius: 0px 16px 16px 0px;
            width:100%;
        }
    }
    h2{
        color: #FFF;
        // color: black;
        margin: 0 auto;
        /* titre de section */
        font-family: Dosis;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 30px;
        &::after {
            content:"";
            display: block;
            text-align: center;
            border-bottom: 2px solid  var(--orange, #F44336);
            width:100px;
            margin:0 auto;
            padding-top: 10px;
        }
    }
}
// CONTACT
 //position:relative;
   

#formulaire{
    // position: absolute;
    width: 70%;
    
    // top:-150px;
    // left: 5%;
    display: flex;
    padding: 51px 90px;
    align-items: center;
    gap: 4rem;
    align-self: stretch;
    border-radius: 16px;
    background: #FFF;
    margin: -150px auto 0;
    // margin-top: -100px;
    /* drop shadow */
    box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
    @media (max-width:976px) {
        flex-direction: column;
    }
    @media (max-width:576px) {
        // width: 50%;
        padding: 31px 50px;
        
    }
    #info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        flex: 1 0 0;
        align-self: stretch;
      
        @media (max-width:976px) {
           gap: 2rem;
            
        }
        .blue-span {
            align-self: stretch;
            color: var(--bleu, #0B89B7);
            text-align: justify;
            margin: 30px 0;
            
            /* titre de section */
            font-family: Dosis;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase; 
        }
        #titre{
            text-align: justify;
            /* text */
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            p:first-child{
                font-family: Dosis;
                font-size: 30px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding-bottom: 20px;
            }
            
        }
        #mail{
            a{
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration: none;
                color: var(--bleu, #0B89B7);
            }
            p:first-child{
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
                padding-bottom: 20px;
            }
        }
        #socials{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            align-self: stretch;
            p:first-child{
                font-family: Dosis;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            a{
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-decoration-line: underline;
                color: var(--bleu, #0B89B7);
                
            }
        }
    }
    #form{
        display: flex;
        // height: 555px;
        flex-direction: column;
        justify-content: space-between;
        // align-items: flex-end;
        flex: 1 0 0;
        gap: 1.5em;
        @media (max-width:576px) {
            // padding: 31px 50px; 
        }
        label{
            color: var(--noir, #000912);
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            @media (max-width:576px) {
                font-size: 16px;
            }
        }
        input, textarea, select{
            // height: 3.5em;
            align-self: stretch;
            background: #F7F7F7;
            border:none;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            padding: .5em 1em;
            font-size: 14px;
        }
        textarea{
            height: 6em;
            // align-self: stretch;
            // background: #F7F7F7;
            // border:none;
        }
        input[type=checkbox]{
            height:auto;
            width: 1em;
        }
        #contact-button{
            display: flex;
            padding: 13px 0px;
            justify-content: center;
            align-items: center;
            align-self: stretch;
            background: var(--bleu, #0B89B7);
            /*text*/
            color: #FFF;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        
    }
}


.footer{
    display: flex;
    flex-wrap: wrap;
    padding: 51px 90px;
    align-items: flex-start;
    gap: 3em;
    align-self: stretch;
    background: var(--bleu, #0B89B7);
    @media (max-width:576px) {
        padding: 31px 50px;
        
    }
    .presentation{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        flex: 1 0 0;
        .footer-logo{
            width: 100px;
            height: 39.579px;
            background-image: url(img/sicteub2.png);
            background-size: contain;
            background-repeat: no-repeat;
        }
        p{
            color: #DDD;
            /* text */
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .socials-logos{
            display: flex;
            align-items: center;
            gap: 12px;
            img{
                width: 30px;
                height: 30px;
                flex-shrink: 0;
                color: #FFF; 
            }
        }
    }
    .horaires {
        color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding : 0 30px;
        gap: 10px;
        @media (max-width:576px) {
            padding: 0;
        }
        p:first-child{
            font-family: Montserrat;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
        }
        ul{
            list-style: none;
        }
        li{
            color: #DDD;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .pages{
        color: #FFF;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding : 0 30px;
        gap: 10px;
        @media (max-width:576px) {
            padding: 0;
        }
        p:first-child{
            font-family: Montserrat;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
        }
        ul{
            list-style: none;
        }
        li a {
            color: #FFF;
            text-decoration: none;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .info-contact{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        p, a{
            color:#FFF;
            text-decoration: none;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        p:first-child{
            font-family: Montserrat;
            font-size: 19px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: uppercase;
        }
        p:nth-of-type(2)::before{
            display: block;
            content:"";
            background: url(img/ant-design_phone-filled.png) no-repeat;
            display:block;
	        float:left;
	        line-height:0;
	        width:1em;
	        height:1em;
	        margin-right:0.5em;
	        background-size:100%;
        }
        a::before {
            display: block;
            content:"";
            background: url(img/dashicons_email.png) no-repeat;
            display:block;
	        float:left;
	        line-height:0;
	        width:1em;
	        height:1em;
	        margin-right:0.5em;
	        background-size:100%;
        }
        p:nth-of-type(3)::before {
            display: block;
            content:"";
            background: url(img/fa6-solid_location-dot.png) no-repeat;
            display:block;
	        float:left;
	        line-height:0;
	        width:1em;
	        height:1em;
	        margin-right:0.5em;
	        background-size:100%;
        }
        
    }
}

// /*********************************
// PAGE NEWS CATEGORY
// *********************************/
#news {
    display: flex;
    // width: 1440px;
    padding: 5em 5em;
    flex-direction: column;
    align-items: flex-start;
    gap: 4em;

    h2{
        // width: 1260px;
        color: var(--noir, #000912);

        /* titre de section */
        font-family: Dosis;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        &::after {
            content:"";
            display: block;
            text-align: center;
            border-bottom: 2px solid  var(--bleu, #0B89B7);
            width:3em;
            // margin:0 auto;
            padding-top: .5em;
        }
    }
    .selection{
        display: flex;
        padding: 3em 2em;
        flex-direction: column;
        align-items: flex-start;
        gap: 2em;
        align-self: stretch;
        background: #E7F4F8;
        h3{
            color: var(--noir, #000912);
            font-family: Dosis;
            font-size: 26px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }
        .filtre{
            display: flex;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            
            .categories{
                display: flex;
                padding: 1em 0;
                align-items: center;
                gap: 2em;
                @media (max-width:$medium) {
                    flex-direction:column;
                }
                span{
                    color: var(--noir, #000912);
                    font-family: Montserrat;
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    @media (max-width:$medium) {
                        display: none;
                    }
                }
                .cat {
                    display: flex;
                    list-style-type: none;
                    gap: 1em;
                    cursor : pointer;
                    flex-wrap: wrap;
                    li{
                        text-transform: uppercase;
                        font-family: Montserrat;
                        font-size: 19px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        border-radius: 6px;
                        border: 1px solid;
                        padding:.5em;
                    }
                }
            }
            .annees{
                display: flex;
                padding: 10px 0px;
                align-items: center;
                gap: 24px;
                flex: 1 0 0;
                span{
                    color: var(--noir, #000912);
                    font-family: Montserrat;
                    font-size: 19px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
            }
        }
    }
    .alaune{
        display: flex;
        // flex-direction: column;
        gap: 2em;
        // align-items: flex-start;
        align-self: stretch;
        border-radius: 16px;
        background: var(--background-background-primary, #FFF);

        /* drop shadow */
        box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
        @media (max-width:$medium) {
            flex-direction:column;
        }
        .show-une-image {
            // max-width: 30%;
            // width:100%;
            // height: 100%;
            border-bottom-left-radius: 16px;
            // display: flex;
            // height: 350px;
            // flex-direction: column;
            // align-items: flex-start;
            // align-self: center;
            flex: 1 0 40%;
        }
        img{
            // max-width: 30%;
            width:100%;
            height:100%;
            object-fit: fill;
            border-bottom-left-radius: 16px;
            // display: flex;
            // height: 350px;
            // flex-direction: column;
            // align-items: flex-start;
            // align-self: center;
            // flex: 1;
        }
        .alaune-container{
            display: flex;
            padding: 2em;
            flex-direction: column;
            // justify-content: center;
            gap: 1.5em;
            align-self: center;
            flex:2;
            h4{
                // color: var(--orange, #F44336);
                font-family: Dosis;
                font-size: 2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
            }
            p{
                color: var(--noir, #000912);
                font-family: Montserrat;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
            .card-button{
                margin-right: 2em;
            }  
            //     a{
            //         text-decoration: none;
            //         color: #FFF;
            //         /* text */
            //         font-family: Montserrat;
            //         font-size: 16px;
            //         font-style: normal;
            //         font-weight: 400;
            //         line-height: normal;
            //     }
            // }
        }
    }

    .card-button{
        display: flex;
        padding: 12px 15px;
        align-self: flex-end;
        // align-items: center;
        border-radius: 6px;
        background: var(--orange, #F44336);
        
        a{
            text-decoration: none;
            color: #FFF;
            /* text */
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .news-cards{
        display: flex;
        align-items: flex-start;
        gap: 1em;
        align-self: stretch;
        flex-wrap: wrap;
        justify-content: space-around;
        @media (max-width:976px) {
            gap:2em;
        }
        .card{
            width: 31%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // flex: 1 1 auto;
            border-radius: 16px;
            background: var(--background-background-primary, #FFF);

            /* drop shadow */
            box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
            @media (max-width:$medium) {
                width: 45%;
            }
            @media (max-width:976px) {
                width: 100%;
            }
            div:nth-child(1){
                height: 250px;
                align-self: stretch;
                border-radius: 16px 16px 0px 0px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 16px 16px 0px 0px;
                    object-fit: cover;
                    object-position:center;
                }
            }
            .card-content{
                display: flex;
                padding: 2em;
                flex-direction: column;
                align-items: flex-start;
                gap: 2em;
                align-self: stretch;
                border-radius: 16px;
                h3{
                    // color: var(--violet, #AB6B98);
    
                    /* titre de section */
                    font-family: Dosis;
                    font-size: 26px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: uppercase;
                    text-decoration: none;
                    a{
                        text-decoration:none;
                    }
                }
                .card-infos{
                    display: flex;
                    padding: 10px 0px;
                    // align-items: center;
                    gap: 6px;
                    // align-self: stretch;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    .news-date {
                        font-size: 12px;
                        display: flex;
                        align-items: center;
                        color: #000;
                    }
                    .point
                    {
                        font-size: 14px;
                        display: flex;
                        align-items: center;
                    }
                    .news-categorie{
                        // display: flex;
                        // align-items: stretch;
                        color: #000;
                        text-transform: uppercase;
                    }
                }
                div p{
                    font-family: Montserrat;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
    }

}
#show-news{
    .news-content{
        // display:flex;
        // justify-content: space-around;
        // gap:2em;
        // padding-top: 2em;
        // clear:both;
        span{
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        // >div{
        //     width: 50%;
        // }
        .description {
            padding-top: 2em;
        }
        .news-image{
            width:50%;
            max-height: 800px;
            margin: 3em 0 1em 2em;
            float: right;
            
            img{
                width:100%;
                max-height: 800px;
                object-fit: cover;

                border-radius: 16px;
                background: var(--background-background-primary, #FFF);
                box-shadow: 0px 16px 20px 0px rgba(112, 144, 176, 0.25);
                /* drop shadow */
            }
            @media (max-width:$medium) {
                width: 100%;
                float: none;
                margin-bottom: 3em;
                margin-left: 0;
            }
        }
    }
} 



.hide {
    display:none;
}

// /* look and feel only, not needed for core menu*/
@keyframes grow {
    0% {
        display: none;
        opacity: 0;
    }

    50% {
        display: block;
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.container, .tpl-container {
    width:90%;
    margin:0 auto;
}

/**************************************
******** TEMPLATE ********************
**************************************/
.tpl-bouton-login-bleu{
    display: flex;
    width: fit-content;
    padding: 7px 5px;
    align-self: flex-end;
    border-radius: 6px;
    background: #0B89B7;
    text-decoration: none;
    color: white;
}

.tpl-container {
    padding: 3em 4em;
    min-height: 1200px;

    h1{
        // width: 1260px;
        color: var(--noir, #000912);

        /* titre de section */
        font-family: Dosis;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 2em;
        &::after {
            content:"";
            display: block;
            text-align: center;
            border-bottom: 2px solid  var(--bleu, #0B89B7);
            width:3em;
            // margin:0 auto;
            padding-top: .5em;
        }
    }
    
    .page-content {
        margin-top: 3em;
        display: flex;
        flex-direction: column;

        /*Text*/
        align-self: stretch;
        color: #000912;
        text-align: justify;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    p{
        align-self: stretch;
        color: #000912;
        text-align: justify;
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .tpl-container-h2 {
        
        align-self: stretch;
        color: var(--bleu, #0B89B7);
        text-align: justify;
        margin-top: 50px;
        
        /* titre de section */
        font-family: Dosis;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase; 
    }
    .tpl-container-h2-small {
        align-self: stretch;
        color: var(--bleu, #0B89B7);
        text-align: justify;
        margin: 30px 0;
        
        /* titre de section */
        font-family: Dosis;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase; 
    }
    .tpl-container-h3 {
        
        align-self: stretch;
        color: var(--noir, #000912);

        /* titre de section */
        font-family: Dosis;
        font-size: 25px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        &::after {
            content:"";
            display: block;
            text-align: center;
            border-bottom: 2px solid  var(--bleu, #0B89B7);
            width:100px;
            // margin:0 auto;
            padding-top: 10px;
        }
    }
    .tpl-container-bloc-bleu {
        display: flex;
        flex-direction: column;
        padding: 51px 90px;
        background: rgba(11, 137, 183, 0.1);
        @media (max-width:576px) {
            padding: 31px 50px; 
        }
    }
    .tpl-container-bloc-blanc {
        display: flex;
        flex-direction: column;
        padding: 51px 90px;
        background: white;
        @media (max-width:576px) {
            padding: 31px 50px; 
        }
    }
    .tpl-bouton-bleu{
        display: flex;
        width: fit-content;
        padding: 12px 15px;
        align-self: flex-end;
        border-radius: 6px;
        background: #0B89B7;
        a{
            text-decoration: none;
            color: white;
        }
    }
    .tpl-bouton-orange{
        display: flex;
        width: fit-content;
        padding: 12px 15px;
        align-self: flex-end;
        border-radius: 6px;
        background: var(--orange, #F44336);
        a{
            text-decoration: none;
            color: white;
        }
    }
    .tpl-color-bleu{
        color:#0B89B7;
        font-size: 20px !important;
    }
    .tpl-color-orange{
        color:var(--orange, #F44336);
        font-size: 20px !important;
    }
    .tpl-color-turquoise{
        color: #00B0F0;
        font-size: 20px !important;
    }
    .tpl-color-bleau-canard{
        color: #0385B5;
        font-size: 20px !important;
    }
    .tpl-color-bleu-roi{
        color: #4472C4;
        font-size: 20px !important;
    }
    .tpl-color-vert{
        color: #70AD47;
        font-size: 20px !important;
    }

    .galeries-cards{
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        flex-wrap: wrap;
        .galerie a{
            text-decoration: none;
            color:#000912;
        }
        .gal-card{
            cursor: pointer;
            position:relative;
            width: 409.333px;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 16px;
            width: 100%;
            background: var(--background-background-primary, #FFF);

            /* drop shadow */
            box-shadow: 0px 16px 40px 0px rgba(112, 144, 176, 0.25);
            .overlay{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                width: 100%;
                opacity: 0;
                transition: .5s ease;
                background-color: black;
                border-radius: 16px;
            }
            &:hover{
                transform: scale(1.03);
                transition: 300ms ease-in;
            }
            &:hover .overlay{
                opacity:0.3;
            }
            >div{
                justify-self: stretch;
                /* drop shadow */
                >img{
                    width:100%;
                    height: 250px;

                    border-radius: 16px 16px 0px 0px;
                }
            }
            .card-content{
                display: flex;
                padding: 24px;
                flex-direction: column;
                align-items: flex-start;
                gap: 30px;
                align-self: stretch;
            }
            h4{
                font-family: Montserrat;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                text-decoration: none;
            }
            .card-infos, .card-description{
                font-family: Montserrat;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}

.document {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color:black;
}
.sm {
    margin-top: 2em;
}
/*****************************
******** GALLERY *************
*****************************/

/* style for demo purposes   */
* {
    margin: 0;
    padding: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  
  /* modal content styles */
  .modal-content-wrapper {
    max-width: 1400px;
    width: 90%;
    display: flex;
    margin: auto;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 70px 0;
  }
  .modal-content-wrapper .image-modal-content {
    flex: 0 0 30%;
    cursor: pointer;
    transition: 300ms ease-out;
    margin-right: 20px;
    margin-bottom: 40px;
  }
  @media (max-width: 992px) {
    .modal-content-wrapper .image-modal-content {
      flex: 0 0 45%;
    }
  }
  @media (max-width: 550px) {
    .modal-content-wrapper .image-modal-content {
      flex: 0 0 100%;
    }
  }
  .modal-content-wrapper .image-modal-content:hover {
    transform: scale(1.03);
    transition: 300ms ease-in;
  }
  .modal-content-wrapper .image-modal-content img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    border: 1px solid #222;
  }
  
  /* modal popup styles */
  .image-modal-popup {
    position: fixed;
    overflow: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    animation: 500ms fadeIn;
    display: none;
    z-index:150;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .image-modal-popup .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-width: 1200px;
    margin: auto;
    margin-top: 30px;
    position: relative;
  }
  
  .image-modal-popup .description,  .image-modal-popup .description h1{
    text-align: center;
    color:white;
    font-size: 1.2rem;
  }
  .image-modal-popup img {
    // width: 60%;
    max-height: calc(200vh - 50px);
    margin-bottom: 10px;
    margin-top: 100px;
    cursor: pointer;
  }
  .image-modal-popup span {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 4rem;
    color: white;
    cursor: pointer;
  }
  .image-modal-popup p {
    margin: 5px auto;
    font-size: 1.1rem;
  }
  .image-modal-popup a {
    margin-bottom: 5px;
    display: inline-block;
    color: #222;
    font-weight: 400;
    text-decoration: none;
    background: #fff;
    margin: 10px 10px;
    padding: 10px 15px;
    border-radius: 6px;
  }
  